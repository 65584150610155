// **  Initial State
const initialState = {
    appointments: [],
    assistants: [],
    assistant: {},
    clinics: [],
    clinic: {},
    incomeClinic: [],
    currentClinic: {},
    discharges: {},
    doctor: {},
    doctors: [],
    doctorPago: {},
    doctorsIncome: [],
    fixedCosts: [],
    patients: [],
    patient: {},
    patientDental: {},
    patientMedical: {},
    patientPhotos: [],
    plans: [],
    chairs: [],
    treatments: [],
    treatment: {},
    treatmentIncome: [],
    providers: [],
    bills: [],
    bill: {},
    payments: [],
    usersCount: [],
    appointmentStats: [],
    paymentsByDoctor: [],
    utilityClinic: [],
    patientsStats: [],
    expensesStats: [],
    products: [],
    product: {},
    notifications: [],
    report: {},
    atm: [],
    orthodontics: [],
    endodontics: []
  }

  const clinicsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CLINICS':
        return { ...state, clinics: action.clinics }
      case 'GET_CLINIC':
        return { ...state, clinic: action.clinic }
      case 'GET_CLINIC_INCOME':
        return { ...state, incomeClinic: action.incomeClinic }
      case 'GET_PATIENTS':
        return { ...state, patients: action.patients }
      case 'GET_PATIENT':
        return { ...state, patient: action.patient }
      case 'GET_PATIENT_DENTAL':
        return { ...state, patientDental: action.patientDental }
      case 'GET_PATIENT_MEDICAL':
        return { ...state, patientMedical: action.patientMedical }
      case 'GET_PATIENT_PHOTOS':
        return { ...state, patientPhotos: action.patientPhotos }
      case 'GET_PATIENT_PLANS':
        return { ...state, plans: action.plans }
      case 'SET_CURRENT_CLINIC':
        return { ...state, currentClinic: action.clinic }
      case 'GET_APPOINTMENTS':
        return { ...state, appointments: action.appointments }
      case 'GET_FIXED_COSTS':
        return { ...state, fixedCosts: action.fixedCosts }
      case 'GET_DISCHARGES':
        return { ...state, discharges: action.discharges }
      case 'GET_DOCTORS':
        return { ...state, doctors: action.doctors }
      case 'GET_DOCTOR':
        return { ...state, doctor: action.doctor }
      case 'GET_DOCTOR_INCOME':
        return { ...state, doctorsIncome: action.doctorsIncome }
      case 'GET_DOCTOR_PAGO':
        return { ...state, doctorPago: action.doctorPago }
      case 'RESET_DOCTOR':
        return { ...state, doctor: {} }
      case 'GET_ASSISTANTS':
        return { ...state, assistants: action.assistants }
      case 'RESET_ASSISTANT':
        return { ...state, assistants: {} }
      case 'GET_CHAIRS':
        return {...state, chairs: action.chairs}
      case 'GET_TREATMENT':
        return {...state, treatments: action.treatments}
      case 'GET_TREATMEN':
        return {...state, treatment: action.treatment}
      case 'GET_TREATMENT_INCOME':
        return {...state, treatmentIncome: action.treatmentIncome}
      case 'GET_PROVIDER':
        return {...state, providers: action.providers}
      case 'GET_PAYMENTS':
        return {...state, payments: action.payments}
      case 'USER_COUNT':
        return { ...state, usersCount: action.usersCount }
      case 'APPOINTMENT_STATUS':
        return { ...state, appointmentStats: action.appointmentStats }
      case 'PAYMENTS_DOCTOR':
        return { ...state, paymentsByDoctor: action.paymentsByDoctor }
      case 'UTILITY_CLINIC':
        return { ...state, utilityClinic: action.utilityClinic }
      case 'PATIENTS_STATS':
        return { ...state, patientsStats: action.patientsStats }
      case 'EXPENSES_STATS':
        return { ...state, expensesStats: action.expensesStats }
      case 'GET_BILLS':
        return { ...state, bills: action.bills }
      case 'GET_BILL':
        return { ...state, bill: action.bill }
      case 'GET_PRODUCTS':
        return { ...state, products: action.products }
      case 'GET_PRODUCT':
        return { ...state, product: action.product }
      case 'NOTIFICATIONS':
        return { ...state, notifications: action.notifications }
      case 'GET_ATM':
        return { ...state, atm: action.atm }
      case 'GET_ORTHODONTICS':
        return { ...state, orthodontics: action.orthodontics }
      case 'GET_ENDODONTICS':
        return { ...state, endodontics: action.endodontics }
      case 'GET_REPORT':
        return { ...state, report: action.report }
      default:
        return state
    }
  }

  export default clinicsReducer
