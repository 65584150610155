// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import clinics from './clinics'
import catalogs from './catalogs'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  clinics,
  catalogs
})

export default rootReducer
