// **  Initial State
const initialState = {
    countries: [],
    currencies: []
  }
  
  const catalogsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_COUNTRIES':
        return { ...state, countries: action.countries }
      case 'GET_CURRENCIES':
        return { ...state, currencies: action.currencies }
      default:
        return state
    }
  }
  
  export default catalogsReducer